import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useIdentityContext } from 'react-netlify-identity';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { useTranslation } from 'react-i18next';

import SEO from '../components/seo';
import { Layout } from '../layout';
import { Container } from '../components/container';
import Form from '../components/Form';
import { Header } from '../components/Header';
import AccountDetailsForm from '../components/AccountDetailsForm';

const CREATE_ACCOUNT = gql`
  mutation CreateAccount(
    $email: String!
    $password: String!
    $fullname: String!
    $street: String!
    $zipCode: String!
    $city: String!
    $phone: String!
    $termsAccepted: Boolean!
  ) {
    createAccount(
      data: {
        username: $email
        password: $password
        type: "RETAIL"
        isEnabled: true
        contact: {
          fullname: $fullname
          address: { street: $street, zipCode: $zipCode, city: $city }
          phone: $phone
          email: $email
        }
        termsAccepted: $termsAccepted
      }
    ) {
      _id
    }
  }
`;

const SignUpPage = props => {
  const [alertMsg, setAlertMsg] = useState(null);
  const [formData, setFormData] = useState({});
  const [loadingIndicator, setLoadingIndicator] = useState(false);

  const { t } = useTranslation();

  const [createAccount] = useMutation(CREATE_ACCOUNT, {
    onError: error => {
      setLoadingIndicator(false)
      setAlertMsg(t('validation.failed_to_create_account'));
      const errMap = {
        'instance not unique': t('validation.already_registered_email'),
      };
      error.graphQLErrors.map(err => {
        const msg = errMap[err.extensions.code];
        if (msg) {
          setAlertMsg(msg);
        }
        return err;    
      });
    },
    onCompleted: async data => {
      setLoadingIndicator(false)
      try {
        await signupUser(formData.email, formData.password, {
          aid: data.createAccount._id,
        },false);

        navigate(`/sign_up_confirmation`);
      } catch (e) {
        setAlertMsg(t('validation.failed_to_create_account'));
      }
    },
  });
  const { signupUser } = useIdentityContext();

  const handleSubmit = data => {
    // event.preventDefault();
    data.termsAccepted = true;
    delete data.confirmPassword;
    setFormData(data); 
    setAlertMsg(null);
    setLoadingIndicator(true)
    createAccount({
      variables: data,
    });
  };

  return (
    <Layout {...props}>
      <SEO title="SIGN UP" keywords={['weboxit', 'boxit']} />
      <Header title={t('sign_up.title')} subtitle={t('sign_up.subtitle')} />
      <Container>
        <Form onSubmit={handleSubmit}>
          <AccountDetailsForm 
            formData={formData}
            message={alertMsg}
            loadingIndicator={loadingIndicator}
           />
        </Form>
      </Container>
    </Layout>
  );
};  

export default SignUpPage;
